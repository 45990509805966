"use strict";

/**
* HTML5 Shiv 3.7.3
*/
!function (a, b) {
  function c(a, b) {
    var c = a.createElement("p"),
        d = a.getElementsByTagName("head")[0] || a.documentElement;
    return c.innerHTML = "x<style>" + b + "</style>", d.insertBefore(c.lastChild, d.firstChild);
  }

  function d() {
    var a = t.elements;
    return "string" == typeof a ? a.split(" ") : a;
  }

  function e(a, b) {
    var c = t.elements;
    "string" != typeof c && (c = c.join(" ")), "string" != typeof a && (a = a.join(" ")), t.elements = c + " " + a, j(b);
  }

  function f(a) {
    var b = s[a[q]];
    return b || (b = {}, r++, a[q] = r, s[r] = b), b;
  }

  function g(a, c, d) {
    if (c || (c = b), l) return c.createElement(a);
    d || (d = f(c));
    var e;
    return e = d.cache[a] ? d.cache[a].cloneNode() : p.test(a) ? (d.cache[a] = d.createElem(a)).cloneNode() : d.createElem(a), !e.canHaveChildren || o.test(a) || e.tagUrn ? e : d.frag.appendChild(e);
  }

  function h(a, c) {
    if (a || (a = b), l) return a.createDocumentFragment();
    c = c || f(a);

    for (var e = c.frag.cloneNode(), g = 0, h = d(), i = h.length; i > g; g++) e.createElement(h[g]);

    return e;
  }

  function i(a, b) {
    b.cache || (b.cache = {}, b.createElem = a.createElement, b.createFrag = a.createDocumentFragment, b.frag = b.createFrag()), a.createElement = function (c) {
      return t.shivMethods ? g(c, a, b) : b.createElem(c);
    }, a.createDocumentFragment = Function("h,f", "return function(){var n=f.cloneNode(),c=n.createElement;h.shivMethods&&(" + d().join().replace(/[\w\-:]+/g, function (a) {
      return b.createElem(a), b.frag.createElement(a), 'c("' + a + '")';
    }) + ");return n}")(t, b.frag);
  }

  function j(a) {
    a || (a = b);
    var d = f(a);
    return !t.shivCSS || k || d.hasCSS || (d.hasCSS = !!c(a, "article,aside,dialog,figcaption,figure,footer,header,hgroup,main,nav,section{display:block}mark{background:#FF0;color:#000}template{display:none}")), l || i(a, d), a;
  }

  var k,
      l,
      m = "3.7.3",
      n = a.html5 || {},
      o = /^<|^(?:button|map|select|textarea|object|iframe|option|optgroup)$/i,
      p = /^(?:a|b|code|div|fieldset|h1|h2|h3|h4|h5|h6|i|label|li|ol|p|q|span|strong|style|table|tbody|td|th|tr|ul)$/i,
      q = "_html5shiv",
      r = 0,
      s = {};
  !function () {
    try {
      var a = b.createElement("a");
      a.innerHTML = "<xyz></xyz>", k = "hidden" in a, l = 1 == a.childNodes.length || function () {
        b.createElement("a");
        var a = b.createDocumentFragment();
        return "undefined" == typeof a.cloneNode || "undefined" == typeof a.createDocumentFragment || "undefined" == typeof a.createElement;
      }();
    } catch (c) {
      k = !0, l = !0;
    }
  }();
  var t = {
    elements: n.elements || "abbr article aside audio bdi canvas data datalist details dialog figcaption figure footer header hgroup main mark meter nav output picture progress section summary template time video",
    version: m,
    shivCSS: n.shivCSS !== !1,
    supportsUnknownElements: l,
    shivMethods: n.shivMethods !== !1,
    type: "default",
    shivDocument: j,
    createElement: g,
    createDocumentFragment: h,
    addElements: e
  };
  a.html5 = t, j(b), "object" == typeof module && module.exports && (module.exports = t);
}("undefined" != typeof window ? window : void 0, document); // Source : https://gist.githubusercontent.com/paulirish/357741/raw/7693126cf78bdea2a0d8353785e98c06d4a619aa/detect%2520IE%2520and%2520version%2520number%2520through%2520injected%2520conditional%2520comments.js

function isIE(version, comparison) {
  var cc = 'IE',
      b = document.createElement('B'),
      docElem = document.documentElement,
      isIE;

  if (version) {
    cc += ' ' + version;

    if (comparison) {
      cc = comparison + ' ' + cc;
    }
  }

  b.innerHTML = '<!--[if ' + cc + ']><b id="iecctest"></b><![endif]-->';
  docElem.appendChild(b);
  isIE = !!document.getElementById('iecctest');
  docElem.removeChild(b);
  return isIE;
}

if (isIE()) {
  var html = document.getElementsByTagName("html")[0];

  if (isIE(9)) {
    html.className += ' IE IE9';
  } else if (isIE(8)) {
    html.className += ' IE IE8';
  } else if (isIE(7)) {
    html.className += ' IE IE7';
  } else if (isIE(6)) {
    html.className += ' IE IE6';
  }
}
/* Placeholders.js v4.0.1 */


!function (a) {
  "use strict";

  function b() {}

  function c() {
    try {
      return document.activeElement;
    } catch (a) {}
  }

  function d(a, b) {
    for (var c = 0, d = a.length; d > c; c++) if (a[c] === b) return !0;

    return !1;
  }

  function e(a, b, c) {
    return a.addEventListener ? a.addEventListener(b, c, !1) : a.attachEvent ? a.attachEvent("on" + b, c) : void 0;
  }

  function f(a, b) {
    var c;
    a.createTextRange ? (c = a.createTextRange(), c.move("character", b), c.select()) : a.selectionStart && (a.focus(), a.setSelectionRange(b, b));
  }

  function g(a, b) {
    try {
      return a.type = b, !0;
    } catch (c) {
      return !1;
    }
  }

  function h(a, b) {
    if (a && a.getAttribute(B)) b(a);else for (var c, d = a ? a.getElementsByTagName("input") : N, e = a ? a.getElementsByTagName("textarea") : O, f = d ? d.length : 0, g = e ? e.length : 0, h = f + g, i = 0; h > i; i++) c = f > i ? d[i] : e[i - f], b(c);
  }

  function i(a) {
    h(a, k);
  }

  function j(a) {
    h(a, l);
  }

  function k(a, b) {
    var c = !!b && a.value !== b,
        d = a.value === a.getAttribute(B);

    if ((c || d) && "true" === a.getAttribute(C)) {
      a.removeAttribute(C), a.value = a.value.replace(a.getAttribute(B), ""), a.className = a.className.replace(A, "");
      var e = a.getAttribute(I);
      parseInt(e, 10) >= 0 && (a.setAttribute("maxLength", e), a.removeAttribute(I));
      var f = a.getAttribute(D);
      return f && (a.type = f), !0;
    }

    return !1;
  }

  function l(a) {
    var b = a.getAttribute(B);

    if ("" === a.value && b) {
      a.setAttribute(C, "true"), a.value = b, a.className += " " + z;
      var c = a.getAttribute(I);
      c || (a.setAttribute(I, a.maxLength), a.removeAttribute("maxLength"));
      var d = a.getAttribute(D);
      return d ? a.type = "text" : "password" === a.type && g(a, "text") && a.setAttribute(D, "password"), !0;
    }

    return !1;
  }

  function m(a) {
    return function () {
      P && a.value === a.getAttribute(B) && "true" === a.getAttribute(C) ? f(a, 0) : k(a);
    };
  }

  function n(a) {
    return function () {
      l(a);
    };
  }

  function o(a) {
    return function () {
      i(a);
    };
  }

  function p(a) {
    return function (b) {
      return v = a.value, "true" === a.getAttribute(C) && v === a.getAttribute(B) && d(x, b.keyCode) ? (b.preventDefault && b.preventDefault(), !1) : void 0;
    };
  }

  function q(a) {
    return function () {
      k(a, v), "" === a.value && (a.blur(), f(a, 0));
    };
  }

  function r(a) {
    return function () {
      a === c() && a.value === a.getAttribute(B) && "true" === a.getAttribute(C) && f(a, 0);
    };
  }

  function s(a) {
    var b = a.form;
    b && "string" == typeof b && (b = document.getElementById(b), b.getAttribute(E) || (e(b, "submit", o(b)), b.setAttribute(E, "true"))), e(a, "focus", m(a)), e(a, "blur", n(a)), P && (e(a, "keydown", p(a)), e(a, "keyup", q(a)), e(a, "click", r(a))), a.setAttribute(F, "true"), a.setAttribute(B, T), (P || a !== c()) && l(a);
  }

  var t = document.createElement("input"),
      u = void 0 !== t.placeholder;

  if (a.Placeholders = {
    nativeSupport: u,
    disable: u ? b : i,
    enable: u ? b : j
  }, !u) {
    var v,
        w = ["text", "search", "url", "tel", "email", "password", "number", "textarea"],
        x = [27, 33, 34, 35, 36, 37, 38, 39, 40, 8, 46],
        y = "#ccc",
        z = "placeholdersjs",
        A = new RegExp("(?:^|\\s)" + z + "(?!\\S)"),
        B = "data-placeholder-value",
        C = "data-placeholder-active",
        D = "data-placeholder-type",
        E = "data-placeholder-submit",
        F = "data-placeholder-bound",
        G = "data-placeholder-focus",
        H = "data-placeholder-live",
        I = "data-placeholder-maxlength",
        J = 100,
        K = document.getElementsByTagName("head")[0],
        L = document.documentElement,
        M = a.Placeholders,
        N = document.getElementsByTagName("input"),
        O = document.getElementsByTagName("textarea"),
        P = "false" === L.getAttribute(G),
        Q = "false" !== L.getAttribute(H),
        R = document.createElement("style");
    R.type = "text/css";
    var S = document.createTextNode("." + z + " {color:" + y + ";}");
    R.styleSheet ? R.styleSheet.cssText = S.nodeValue : R.appendChild(S), K.insertBefore(R, K.firstChild);

    for (var T, U, V = 0, W = N.length + O.length; W > V; V++) U = V < N.length ? N[V] : O[V - N.length], T = U.attributes.placeholder, T && (T = T.nodeValue, T && d(w, U.type) && s(U));

    var X = setInterval(function () {
      for (var a = 0, b = N.length + O.length; b > a; a++) U = a < N.length ? N[a] : O[a - N.length], T = U.attributes.placeholder, T ? (T = T.nodeValue, T && d(w, U.type) && (U.getAttribute(F) || s(U), (T !== U.getAttribute(B) || "password" === U.type && !U.getAttribute(D)) && ("password" === U.type && !U.getAttribute(D) && g(U, "text") && U.setAttribute(D, "password"), U.value === U.getAttribute(B) && (U.value = T), U.setAttribute(B, T)))) : U.getAttribute(C) && (k(U), U.removeAttribute(B));

      Q || clearInterval(X);
    }, J);
    e(a, "beforeunload", function () {
      M.disable();
    });
  }
}(void 0);